@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

$themes: (
  flint,
  juice,
  pool,
);

$backgrounds: (
        flint: $flint,
        juice: $juice,
        pool: $pool
);

$texts: (
        flint: $snow,
        juice: $snow,
        pool: $anchor,
);

$hover-backgrounds: (
        flint: $ink,
        juice: $persimmon,
        pool: $lagoon,
);

$hover-texts: (
        pool: $snow,
);

$borders: (
        flint: $pool,
        juice: $date,
        pool: $flint,
);

$texts-secondary: (
  flint: $flint,
  juice: $juice,
  pool: $anchor
);

$borders-secondary: (
  flint: $flint,
  juice: $juice,
  pool: $pool,
);

//disabled button
$disabled-text: $tinsel;
$disabled-background: $fog;
$button-height: 36px;

.ins-buttons {
  height: $button-height;
  border: none;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0 16px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  margin: 0;
  outline: none;
  white-space: nowrap;
  min-width: fit-content;
  text-decoration: none;
  cursor: pointer;
  @include font-mixin(16px, normal, 16px, $ocean);

  .js-focus-visible &:focus:not(.focus-visible) {
    outline: none;
  }

  [class^="icon-"] {
    font-size: 28px;
    font-weight: 700;

    &:only-child {
      margin: 0 -16px;
    }

    &:first-child {
      margin-left: -16px;
    }
    &:last-child {
      margin-right: -16px;
    }
  }

  &.button-primary, &.button-secondary {
    border-radius: calc($button-height / 2);
    color: $ocean;

    &:hover, &.hover {
      background: $fog;
    }

    &:focus, &.focused, &.focus-visible {
      box-shadow: 0 0 7px 1px rgba(51, 71, 91, 0.7);
      background: $snow;
    }

    &:active, &.pressed {
      box-shadow: 0 0 7px 1px rgba(51, 71, 91, 0.7);
      background: $snow;
      color: $storm;
    }

    &:disabled, &.disabled {
      cursor: default;
      background: $disabled-background;
      color: $disabled-text;
    }
  }

  &.button-primary {
    @each $theme in $themes {
      &.#{$theme} {
        border-radius: 4px;
        background: map-get($backgrounds, $theme);
        color: map-get($texts, $theme);

        &:hover, &.hover {
          background: map-get($hover-backgrounds, $theme);
          border: solid 1px map-get($borders, $theme);
          color: map-get($hover-texts, $theme);
        }

        &:focus, &.focused {
          box-shadow: 2px 2px 4px 0 rgba(45, 51, 62, 0.3);
          background: map-get($hover-backgrounds, $theme);
          border: solid 1px map-get($borders, $theme);
          color: map-get($hover-texts, $theme);
        }

        &:active, &.pressed {
          box-shadow: 2px 2px 4px 1px rgba(45, 51, 62, 0.3);
          background: map-get($backgrounds, $theme);
          border: none;
          color: map-get($texts, $theme);
        }

        &:disabled, &.disabled {
          cursor: default;
          border: none;
          box-shadow: none;
          background: $disabled-background;
          color: $disabled-text;
        }
      }
    }
  }

  &.button-secondary {
    @each $theme in $themes {
      &.#{$theme} {
        background: none;
        border-radius: 4px;
        border: solid 1px map-get($borders-secondary, $theme);
        color: map-get($texts-secondary, $theme);

        &:hover, &.hover, &:focus, &.focused {
          box-shadow: 0 0 7px 1px rgba(51, 71, 91, 0.7);
        }

        &:focus, &.focused {
          border: solid 2px map-get($borders-secondary, $theme);
        }

        &:active, &.pressed {
          box-shadow: 2px 2px 4px 0 rgba(45, 51, 62, 0.3);
          border: solid 1px map-get($borders-secondary, $theme);
        }

        &:disabled, &.disabled {
          cursor: default;
          box-shadow: none;
          border: solid 1px $disabled-background;
          color: $disabled-text;
        }
      }
    }
  }
}
