@import "../styles/colors.scss";

@mixin font-mixin($font-size, $font-weight, $line-height, $color) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: $color;
}

@mixin button-mixin($label-left-margin: 8px, $label-right-margin: 5px) {
  &::ng-deep .mat-checkbox-inner-container {
    height: 14px;
    width: 14px;
    margin: 5px;
    & .mat-checkbox-frame {
      border-color: #3a9697;
    }
  }
  &::ng-deep .mat-checkbox-label {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 22px;
    margin-left: $label-left-margin;
    margin-right: $label-right-margin;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $primarycolor2-800;
  }
  &::ng-deep .mat-checkbox-layout {
    margin: 0;
    overflow: hidden;
  }
}

@mixin explore-more-btn($color, $border, $icon-size) {
  color: $color;
  border: #{$border};
  border-radius: 2px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  font-style: normal;
  text-align: center;
  padding-right: 16px;
  padding-left: 8px;
  font-stretch: normal;
  letter-spacing: 0.5px;
  align-content: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;

  &__icon {
    font-size: $icon-size;
    line-height: 40px;
    margin-right: 8px;
  }
}

@mixin triangle-pointers ($top, $bottom, $right, $left) {
  position: absolute;
  content: " ";
  border: 6px solid transparent;
  top: $top;
  bottom: $bottom;
  right: $right;
  left: $left;
}

@mixin pin-button {
  background-color: transparent;

  &:hover, &:focus {
    color: $primarycolor3-700;
  }

  &:focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;

    ::ng-deep .mat-button-focus-overlay {
      opacity: 0;
    }
  }
}

@mixin back-button {
  position: absolute;
  margin-top: -50px;
  padding: 0;
  @include font-mixin(14px, 500, 1.14, $primarycolor3-900);
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.5px;
  border: none;

  .icon {
    margin-right: 8px;
    font-size: 12px;
  }
  .text {
    &:hover {
      text-decoration: underline;
    }
  }

  &:focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

@mixin ac-tab-content-container {
  margin: 68px 15% 0;
  padding: 10px 0;
  min-height: 280px;

  &.cdk-program-focused {
    outline: none;
  }
}

@mixin full-card-nav-tabs {
  :host /deep/ .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $primarycolor3-800;
  }

  :host /deep/ .mat-tab-header {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12);
    border-bottom-width: 0;
    padding: 0 16%;
  }

  :host /deep/ .mat-tab-label-container {
    flex-grow: 0;
    margin: 0 auto;
  }

  :host /deep/ .mat-tab-labels {
    justify-content: start;
    align-content: center;

    .mat-tab-label {
      padding: 16px 36px;
      min-width: 0;
      opacity: 1;

      &.mat-tab-label-active:focus {
        background-color: transparent;
      }

      &.cdk-keyboard-focused:focus {
        background-color: $primarycolor3-50;
      }

      &:hover {
        .mat-tab-label-content {
          color: $primarycolor3-900;
        }
      }
    }

    .mat-tab-label-content {
      text-transform: uppercase;
      font-family: Roboto, sans-serif;
      letter-spacing: 0.1px;
      text-align: center;
      @include font-mixin(14px, 500, 1.14, rgba(0, 0, 0, 0.7));
    }

    .mat-tab-label-active {
      .mat-tab-label-content {
        color: $primarycolor3-900;
      }
    }
  }

  .bottom {
    min-height: calc(100vh - #{$global-header-height} - #{$workbench-height} - #{$universal-footer-height} - 56px);
    padding-bottom: 56px;
    background-color: #fff;
  }
}

@mixin content-positioning() {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
}
