@import "styles/mixins.scss";
@import "styles/colors.scss";

.tippy-box {
  @include font-mixin(14px, normal, 1.43, $white);
  background-color: $primarycolor2-800;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
  opacity: 0.9;

  .tippy-content {
    margin-top: 8px;
    padding: 16px;
  }
}
