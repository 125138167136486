$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-filter: "\e900";
$icon-lists: "\e901";
$icon-search: "\e902";
$icon-profile: "\e903";
$icon-library: "\e904";
$icon-chat: "\e905";
$icon-cite: "\e906";
$icon-more: "\e908";
$icon-share: "\e909";
$icon-close: "\e90a";
$icon-back: "\e90b";
$icon-help: "\e90d";
$icon-concepts: "\e90e";
$icon-list: "\e90f";
$icon-wheel: "\e910";
$icon-add: "\e911";
$icon-add-search-query: "\e912";
$icon-order: "\e913";
$icon-angle-down-solid: "\e914";
$icon-angle-up-solid: "\e915";
$icon-angle-down-thin: "\e916";
$icon-move: "\e917";
$icon-lock: "\e918";
$icon-exclude: "\e919";
$icon-lock-open: "\e91a";
$icon-error: "\e91b";
$icon-calendar: "\e91c";
$icon-rotate: "\e91d";
$icon-overflow-toggle: "\e91e";
$icon-user: "\e91f";
$icon-email: "\e920";
$icon-related-resources: "\e921";
$icon-contributors: "\e922";
$icon-topics: "\e923";
$icon-failure-path1: "\e924";
$icon-failure-path2: "\e925";
$icon-success-path1: "\e927";
$icon-success-path2: "\e928";
$icon-ins-wheel: "\e929";
$icon-overflow: "\e92a";
$icon-ins-search: "\e92c";
$icon-ins-user: "\e92d";
$icon-ins-info: "\e92e";
