@import "styles/mixins";
@import "styles/colors";

/// modal: make it closer to our bootstrap modals ///
#LT_LB_wrapper #LT_LB_overlay {
  background-color: #000 !important;
  opacity: 0.5 !important;
}

#LT_LB {
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.3rem !important;
  background-clip: padding-box !important;
}

#LT_LB_header {
  background: $white !important;
  border: none !important;
}

#LT_LB_title {
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 28px !important;
  color: $storm !important;
}

#LT_LB_close {
  font-size: 35px !important;
}
//- modal -//
