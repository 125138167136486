@import "../colors.scss";

.app-button {
  height: 2.5em;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  line-height: 18px;
  margin: 0;
  outline: none;
  text-decoration: none;
  border: solid 1px transparent;

  &.medium {
    height: 36px;
  }

  &.button-text {
    color: $primarycolor3-900;
    background: transparent;

    &:hover, &:focus, &:active {
      background-color: $interactive-btn-color1;
    }

    &:disabled {
      color: rgba(0, 0, 0, .12);
      background: none;
    }
  }

  &.button-outlined {
    @extend .button-text;

    border: 1px solid $primarycolor3-900;

    &:hover {
      background-color: $interactive-btn-color2;
    }

    &:disabled {
      border-color: rgba(0, 0, 0, 0.12);
      background-color: unset;
    }
  }

  &.button-raised {
    color: white;
    background: $primarycolor3-900;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);

    &:hover {
      background: $primarycolor3-900;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 4px 2px 0 rgba(0, 0, 0, 0.24);
    }

    &:focus {
      background: $primarycolor3-700;
    }

    &:active {
      background: $primarycolor3-900;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
    }

    &:disabled, &.disabled {
      background-color: rgba(0, 0, 0, 0.12);
      box-shadow: none;
    }
  }

  &.button-cancel {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background: white;
    color: rgba(0, 0, 0, 0.87);

    &:hover {
      background: white;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 4px 2px 0 rgba(0, 0, 0, 0.24);
    }

    &:active {
      background: white;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
      box-shadow: none;
    }
  }

  &.button-labelled {
    font-size: 10px;
    background-color: transparent;
    height: 46px;
    min-width: 50px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    color: $flint;
    padding: 5px 5px 0 5px;
    margin: 0;
    border: 1px solid transparent;
    letter-spacing: normal;
    text-transform: none;

    .icon {
      font-size: 24px;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(lg) {
        font-size: 20px;
      }

      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }

    .text {
      font-size: 10px;
      text-align: center;
    }

    &:hover,
    &:focus {
      background-color: $fog;
      border-radius: 5px;
      border: 1px solid $lightSlateGrey;
    }

    &:focus {
      outline: none;
    }
  }
}

.close-btn {
  height: 100%;
  order: 2;
  display: flex;
  padding: 4px 8px 12px 0;
  color: $anchor;
  font-size: 20px;
  border: none;

  &:active {
    outline: none;
  }

  & .icon-close {
    border-radius: 50%;
    padding: 9px;

    &:hover {
      background-color: $fog;
    }

    &:active {
      background-color: $pumice;
    }
  }

  &:disabled {
    opacity: 0.6;
  }
}

.button-disabled {
  opacity: 0.6;
  cursor: default;

  &:hover, &:focus {
    text-decoration: none;
  }
}
