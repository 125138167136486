$white: #fff;
$white-0-0: #f2f3f5;

$black: #000;
$black-06: rgba(0, 0, 0, 0.06);
$black-1: rgba(0, 0, 0, 0.1);
$black-2: rgba(0, 0, 0, .2);
$black-3: rgba(0, 0, 0, 0.3);
$black-7: rgba(0, 0, 0, 0.7);
$black-12: rgba(0, 0, 0, 0.12);
$black-14: rgba(0, 0, 0, 0.14);
$black-24: rgba(0, 0, 0, 0.24);
$black-87: rgba(0, 0, 0, 0.87);

$error-red: #e0000b;

$ship-cove: #6A86B6;

$cyprus: #616263;

// Primary color 1
$primarycolor1-50: #d6e1f2;
$primarycolor1-100: #9eb6db;
$primarycolor1-200: #6887b6;
$primarycolor1-300: #446393;
$primarycolor1-400: #2e4c79;
$primarycolor1-500: #1b375f;
$primarycolor1-600: #112a51;
$primarycolor1-700: #081e3f;
$primarycolor1-800: #051732;
$primarycolor1-900: #010d1f;


// Primary color 2
$primarycolor2-50: #dee2e5;
$primarycolor2-100: #b3b6ba;
$primarycolor2-200: #9a9ea1;
$primarycolor2-300: #828689;
$primarycolor2-400: #75787b;
$primarycolor2-500: #606366;
$primarycolor2-600: #555759;
$primarycolor2-700: #4a4b4c;
$primarycolor2-800: #38393a;
$primarycolor2-800: #2c2e30;
// Colors which are not in Zeplin I marked with zero and started new count with zero
$primarycolor2-0-0: #9b9b9b;
$primarycolor2-0-100: #212121;
$primarycolor2-0-200: #76838d;

// Primary color 3
$primarycolor3-50: #e3fefe;
$primarycolor3-100: #b1f6f6;
$primarycolor3-200: #87e9e9;
$primarycolor3-300: #63d5d5;
$primarycolor3-400: #52cccc;
$primarycolor3-500: #40c0c0;
$primarycolor3-600: #29aeae;
$primarycolor3-700: #219d9d;
$primarycolor3-800: #198a8a;
$primarycolor3-900: #097c7c;

// Secondary color 1
$secondarycolor1-50: #f9edea;
$secondarycolor1-100: #ffc8bc;
$secondarycolor1-200: #ff8d74;
$secondarycolor1-300: #f36c4f;
$secondarycolor1-400: #ea5737;
$secondarycolor1-500: #e04726;
$secondarycolor1-600: #d13a19;
$secondarycolor1-700: #c12e0e;
$secondarycolor1-800: #a32206;
$secondarycolor1-900: #911b02;

// Secondary color 2
$secondarycolor2-50: #fff1de;
$secondarycolor2-100: #fed7a2;
$secondarycolor2-200: #ffc67a;
$secondarycolor2-300: #ffb95b;
$secondarycolor2-400: #faaa3d;
$secondarycolor2-500: #f89c20;
$secondarycolor2-600: #ec8f11;
$secondarycolor2-700: #e28200;
$secondarycolor2-800: #c97300;
$secondarycolor2-900: #a36109;

// Secondary color 3
$secondarycolor3-50: #ebfed8;
$secondarycolor3-100: #d3f8af;
$secondarycolor3-200: #b3e77f;
$secondarycolor3-300: #9ed766;
$secondarycolor3-400: #8cc554;
$secondarycolor3-500: #7ebc41;
$secondarycolor3-600: #72ae37;
$secondarycolor3-700: #69a230;
$secondarycolor3-800: #5c9326;
$secondarycolor3-900: #4a870e;

// Secondary color 4
$secondarycolor4-50: #e0f4f7;
$secondarycolor4-100: #cae6ea;
$secondarycolor4-200: #b7dce1;
$secondarycolor4-300: #a0d1d7;
$secondarycolor4-400: #85c1c9;
$secondarycolor4-500: #6eb3bc;
$secondarycolor4-600: #59a3ad;
$secondarycolor4-700: #4f959e;
$secondarycolor4-800: #437f87;
$secondarycolor4-900: #33686f;

//Inspire colors

/***********Primary************/

$flint: #425B76;
$juice: #E45D3F;
$pool: #7FD1DE;
$pool-25: rgba(127, 209, 222, 0.25);
$persimmon: #C0462B;
$date: #893623;
$storm: #33475b;

/***********Secondary************/
$ocean: #0096AD;
$lagoon: #2B83AB;
$orchid: #BD518B;
$ink: #33475B;

/***********Supporting************/
$anchor: #454F5B;
$cardinal: #C21414;
$foliage: #0C7E11;

/***********Neutrals************/
$snow: #FFFFFF;
$pearl: #F6F8F9;
$dust: #F1F4F6;
$fog: #EAEDEF;
$pumice: #DEE1E4;
$overcast: #CED2D6;
$tinsel: #BABEC3;
$earl-grey: #A7ABB1;
$gravel: #92979E;
$slate: #666B74;
$graphite: #535862;
$wrought-iron: #494E59;
$wrought-iron-3: rgba(73, 78, 89, 0.3);
$wrought-iron-55: rgba(73, 78, 89, 0.55);
$charcoal: #3E434E;
$ink-1: #2D333E;
$nightfall: #141619;
$flint: #425b76;
$privacy-policy-links-color: $primarycolor3-900;

$ins-blue-text: #516f90;
$ins-disabled-background: #eaf0f6;
$ins-disabled-frame: #cbd6e2;
$ins-skip-link: #574751;
