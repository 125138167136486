@import "styles/colors.scss";
@import "styles/common.scss";
@import "styles/breakpoints.scss";
@import "styles/inspire-breakpoints.scss";

@mixin font-withoutcolor-mixin ($font-size, $font-weight, $line-height) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
}
@mixin font-mixin($font-size, $font-weight, $line-height, $color) {
  @include font-withoutcolor-mixin($font-size, $font-weight, $line-height);
  color: $color;
}

@mixin button-mixin($label-left-margin: 8px, $label-right-margin: 5px) {
  &::ng-deep .mat-checkbox-inner-container {
    height: 14px;
    width: 14px;
    margin: 5px;
    & .mat-checkbox-frame {
      border-color: #3a9697;
    }
  }
  &::ng-deep .mat-checkbox-label {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 22px;
    margin-left: $label-left-margin;
    margin-right: $label-right-margin;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: $primarycolor2-800;
  }
  &::ng-deep .mat-checkbox-layout {
    margin: 0;
    overflow: hidden;
  }
}

@mixin triangle-pointers ($top, $bottom, $right, $left) {
  position: absolute;
  content: " ";
  border: 6px solid transparent;
  top: $top;
  bottom: $bottom;
  right: $right;
  left: $left;
}

@mixin toolbar-button {
  background-color: transparent;
  font-size: 24px;
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  color: $flint;
  padding: 0;
  margin: 0;
  border: none;

  &:hover,
  &:focus {
    background-color: $fog;
    border-radius: 50%;
  }

  &:focus {
    outline: none;
  }
}

@mixin back-button {
  position: absolute;
  margin-top: -50px;
  padding: 0;
  @include font-mixin(14px, 500, 1.14, $storm);
  letter-spacing: 0.5px;
  border: none;

  .icon {
    margin-right: 8px;
    font-size: 12px;
  }
  .text {
    &:hover {
      text-decoration: underline;
    }
  }

  &:focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

@mixin ac-tab-content-container {
  margin: 68px 15% 0;
  padding: 10px 0;
  min-height: 280px;

  &.cdk-program-focused {
    outline: none;
  }
}

@mixin full-card-nav-tabs {
  :host ::ng-deep .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $primarycolor3-800;
  }

  :host ::ng-deep .mat-tab-header {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12);
    border-bottom-width: 0;
    padding: 0 16%;
  }

  :host ::ng-deep .mat-tab-label-container {
    justify-content: center;
  }

  :host ::ng-deep .mat-tab-list {
    flex-grow: 0;
  }

  :host ::ng-deep .mat-tab-labels {
    .mat-tab-label {
      padding: 16px 36px;
      opacity: 1;

      &.mat-tab-label-active:focus {
        background-color: transparent;
      }

      &.cdk-keyboard-focused:focus {
        background-color: $interactive-bg-color;
      }

      &:hover {
        .mat-tab-label-content {
          color: $primarycolor3-900;
        }
      }
    }

    .mat-tab-label-content {
      text-transform: uppercase;
      letter-spacing: 0.1px;
      text-align: center;
      @include font-mixin(14px, 500, 1.14, rgba(0, 0, 0, 0.7));
    }

    .mat-tab-label-active {
      .mat-tab-label-content {
        color: $primarycolor3-900;
      }
    }
  }

  .bottom {
    min-height: calc(100vh - #{$global-header-height} - #{$bookshelf-height} - #{$universal-footer-height} - 56px);
    padding-bottom: 56px;
    background-color: #fff;
  }
}

@mixin content-positioning() {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
}

@mixin bs-content-positioning() {
  overflow-y: auto;
  height: 100%;
}

@mixin topic-resource-container($column, $row) {
  -ms-grid-column: $column;
  -ms-grid-column-span: 1;
  grid-column: $column;
  -ms-grid-row: $row;
  -ms-grid-row-span: 1;
  grid-row: $row;
}

@mixin hidden-but-readable-by-screen-readers {
  opacity: 0;
  width: 0;
  overflow: hidden;
}

@mixin mat-tab-pagination-chevron($color,$border-width,$height,$width) {
  border-color: $color;
  border-width: $border-width $border-width 0 0;
  height: $height;
  width: $width;
}

@mixin outline-style($color) {
  outline: 1px dotted $color;
  outline: 5px auto -webkit-focus-ring-color;
}

@mixin common-rollup-tab-content-card {
  .rollup-tabs-content-card-col-mw-fixed {
    max-width: 139px !important;
    min-width: 125px !important;
    padding-right: 5px;
  }
}

@mixin common-rollup-tab-content-card-icon {
  font-size: $rollup-tab-icon-width;
  margin-right: $rollup-tab-icon-margin;
}

@mixin remove-dropdown-arrow {
  &::after {
    display: none;
  }
}

@mixin bookshelf-account-tab-content {
  padding: 20px 80px 0;

  @include media-breakpoint-down(md) {
    padding: 20px 40px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 20px 0;
  }
}

@mixin override-unbound-link($font-size: 14px, $xs-font-size: 14px)  {
  &, &:visited {
    @include font-mixin($font-size, normal, 1.5, $wave);
    font-weight: normal !important;
    border: 0;
    padding: 0 !important;
    margin: 0 !important;
    background: transparent;
  }

  &:hover {
    text-decoration: underline !important;
    color: $wave !important;
    background: inherit;
  }

  @include media-breakpoint-down(sm) {
    font-size: $xs-font-size;
  }
}

@mixin override-unbound-border {
  border: none;
  border-radius: 0;
}

@mixin override-unbound-button {
  background: $flint;
  font-weight: 500;
  letter-spacing: normal;
  font-size: 14px;

  &:hover {
    color: $white;
  }
}

@mixin header-button-states($mouse-focus-color, $mouse-hover-color, $touch-focused-color, $hover-color: null) {
  @if ($hover-color) {
    border: 1px solid transparent;

    &:hover, &:focus, &:active {
      background-color: $hover-color;
      outline: none;
    }

    &:focus, &:active {
      border: 1px solid $lightSlateGrey;
    }
  }

  &.cdk-mouse-focused {
    &:focus {
      background-color: $mouse-focus-color;
    }

    &:hover, &:active {
      background-color: $mouse-hover-color;
    }
  };

  &.cdk-touch-focused {
    &:hover, &:focus, &:active {
      background-color: $touch-focused-color;
    }
  }
}

@mixin cover-shadow {
  box-shadow: 0 2px 4px 0 $black-5;
}

%header__button {
  border: none;
  padding: 0;
  margin: 0;
  @include font-mixin(16px, normal, normal, $storm);
  text-transform: none;
  outline: none;
  border-radius: 0;
}

@mixin header-icon-button() {
  @extend %header__button;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;

  &:hover, &:focus, &:active {
    cursor: pointer;
  }

  @include header-button-states(unset, $fog, unset, $fog);
}

@mixin bulk-panel-button {
  height: 32px;
  font-size: 14px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  line-height: 32px;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}
