$icomoon-font-path: "./inspire/styles/icons/fonts";
@import "./variables";
@import "./inspire-bootstrap-variables";
@import "bootstrap/scss/bootstrap";
@import "./theme";
@import "./icons-style";
@import "./reset";
@import "./components";
@import "./overrides/syndetics-unbound";
@import "./overrides/perfect-scrollbar";

@import "./inspire/styles/styles.scss";
@import "./components/button-inverse";
@import "./components/select";
@import "./inspire-breakpoints.scss";
@import "tippy.js/dist/tippy.css";

html, body {
  height: 100%;
}

body {
  background-color: $snow;
  -webkit-font-smoothing: antialiased;
}

html[lang="ar"] {
  body {
    direction: rtl;
  }
}

.cdk-overlay-container {
  z-index: $zindex-modal;
}

.cdk-mouse-focused {
  outline: none;
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.mat-expansion-panel-header-title {
  color: $dropdown-text-color;
}

.icon-help {
  &:before {
    font-size: 20px;
  }
}

.mat-expansion-indicator::after {
  color: $lagoon;
}

.flex-shrink-below-content-size {
  min-width: 0;
}

/* override bootstrap styles here only if there are no variables we can set in inspire-bootstrap-variables */
/* this should ONLY be used when a variable is not available in bootstrap Sass */
.modal-title {
  color: $storm;
}

.select:focus, .form-control:focus {
  border-color: $pacificBlue;
}

.btn:focus {
  box-shadow: 0 0 0 0.2rem $pacificBlue;
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;

  &--one {
    -webkit-line-clamp: 1;
  }

  &--two {
    -webkit-line-clamp: 2;
  }

  &--three {
    -webkit-line-clamp: 3;
  }

  &--five {
    -webkit-line-clamp: 5;
  }
}
