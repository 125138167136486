@import "../inspire-bootstrap-variables";

.inspire-custom-modal .modal-dialog {
  margin: 0;
  width: $modal-sm;

  & .modal-content {
    height: 100vh;
  }
}

@include media-breakpoint-down(xs) {
  .inspire-custom-modal .modal-dialog .modal-content {
    border: none;
    border-radius: 0;
  }
}

@include media-breakpoint-up(sm) {
  .inspire-custom-modal .modal-dialog {
    margin: auto;
    max-width: $modal-md;

    & .modal-content {
      height: 100%;
    }
  }
}

@include media-breakpoint-up(md) {
  .inspire-custom-modal .modal-dialog {
    max-width: $modal-lg;
  }
}

.modal-backdrop.show {
  z-index: $zindex-modal-backdrop !important;

  &.second-modal-backdrop {
    z-index: $zindex-modal !important;
  }
}

.modal.facet-modal {
  .modal-dialog {
    width: 100%;
    margin: 0 auto;
    @include media-breakpoint-up(sm) {
      .modal-dialog {
        width: 800px;
      }
    }
  }
}

.bulk-hold-modal {
  @extend .inspire-custom-modal;

  @include media-breakpoint-up(sm) {
    .modal-dialog {
      max-width: 90vw;
    }
  }

  @include media-breakpoint-up(xl) {
    .modal-dialog {
      max-width: 1100px;
    }
  }
}
