@use '@angular/material' as mat;
$icon-font-path: "bootstrap-sass/assets/fonts/bootstrap/";
@import "./icons/style";
@import "./reset";
@import "./components";

body {
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
}

html[lang="ar"] {
  body {
    direction: rtl;
  }
}

.cdk-mouse-focused {
  outline: none;
}

// angular material theming
@import "@angular/material/theming";

$conv-blue: (
  50 : #e0ecf8,
  100 : #b3d0ee,
  200 : #80b1e2,
  300 : #4d91d6,
  400 : #267ace,
  500 : #0062c5,
  600 : #005abf,
  700 : #0050b8,
  800 : #0046b0,
  900 : #0034a3,
  A100 : #cedaff,
  A200 : #9bb3ff,
  A400 : #688dff,
  A700 : #4e79ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$conv-green: (
  50 : #e7f2f3,
  100 : #c4e0e0,
  200 : #9dcbcb,
  300 : #75b6b6,
  400 : #58a6a7,
  500 : #3a9697,
  600 : #348e8f,
  700 : #2c8384,
  800 : #25797a,
  900 : #186869,
  A100 : #a3feff,
  A200 : #70fdff,
  A400 : #3dfcff,
  A700 : #24fcff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

@include mat.core();

$conv-app-primary: mat.define-palette($conv-green);
$conv-app-accent: mat.define-palette($conv-blue);

$conv-app-theme: mat.define-light-theme((
  color: (
    primary: $conv-app-primary,
    accent: $conv-app-accent,
  )
));

@include mat.all-component-themes($conv-app-theme);
