$white: #fff;
$white-0-0: #f2f3f5;
$white-87: rgba(255, 255, 255, 0.87);

$black: #000;
$black-06: rgba(0, 0, 0, 0.06);
$black-1: rgba(0, 0, 0, 0.1);
$black-2: rgba(0, 0, 0, 0.2);
$black-3: rgba(0, 0, 0, 0.3);
$black-5: rgba(0, 0, 0, 0.4);
$black-7: rgba(0, 0, 0, 0.7);
$black-12: rgba(0, 0, 0, 0.12);
$black-24: rgba(0, 0, 0, 0.24);
$black-80: rgba(0, 0, 0, 0.8);
$black-87: rgba(0, 0, 0, 0.87);

$error-red: #e0000b;

$ship-cove: #6A86B6;

$cyprus: #616263;

$light-gray: #8d8d8e;

// Primary color 1
$primarycolor1-50: #d6e1f2;
$primarycolor1-100: #9eb6db;
$primarycolor1-200: #6887b6;
$primarycolor1-300: #446393;
$primarycolor1-400: #2e4c79;
$ins-blue-text: #516f90;
$primarycolor1-500: #1b375f;
$primarycolor1-600: #112a51;
$primarycolor1-700: #081e3f;
$primarycolor1-800: #051732;
$primarycolor1-900: #010d1f;


// Primary color 2
$primarycolor2-50: #dee2e5;
$primarycolor2-100: #b3b6ba;
$primarycolor2-200: #9a9ea1;
$primarycolor2-300: #828689;
$primarycolor2-400: #75787b;
$primarycolor2-500: #606366;
$primarycolor2-600: #555759;
$primarycolor2-700: #4a4b4c;
$primarycolor2-800: #38393a;
$primarycolor2-900: #2c2e30;
// Colors which are not in Zeplin I marked with zero and started new count with zero
$primarycolor2-0-0: #9b9b9b;
$primarycolor2-0-100: #212121;
$primarycolor2-0-200: #76838d;

// Primary color 3
$primarycolor3-50: #e3fefe;
$primarycolor3-100: #b1f6f6;
$primarycolor3-200: #87e9e9;
$primarycolor3-300: #63d5d5;
$primarycolor3-400: #52cccc;
$primarycolor3-500: #40c0c0;
$primarycolor3-600: #29aeae;
$primarycolor3-700: #219d9d;
$primarycolor3-800: #198a8a;
$primarycolor3-900: #097c7c;

// Secondary color 1
$secondarycolor1-50: #f9edea;
$secondarycolor1-100: #ffc8bc;
$secondarycolor1-200: #ff8d74;
$secondarycolor1-300: #f36c4f;
$secondarycolor1-400: #ea5737;
$secondarycolor1-500: #e04726;
$secondarycolor1-600: #d13a19;
$secondarycolor1-700: #c12e0e;
$secondarycolor1-800: #a32206;
$secondarycolor1-900: #911b02;

// Secondary color 2
$secondarycolor2-50: #fff1de;
$secondarycolor2-100: #fed7a2;
$secondarycolor2-200: #ffc67a;
$secondarycolor2-300: #ffb95b;
$secondarycolor2-400: #faaa3d;
$secondarycolor2-500: #f89c20;
$secondarycolor2-600: #ec8f11;
$secondarycolor2-700: #e28200;
$secondarycolor2-800: #c97300;
$secondarycolor2-900: #a36109;

// Secondary color 3
$secondarycolor3-50: #ebfed8;
$secondarycolor3-100: #d3f8af;
$secondarycolor3-200: #b3e77f;
$secondarycolor3-300: #9ed766;
$secondarycolor3-400: #8cc554;
$secondarycolor3-500: #7ebc41;
$secondarycolor3-600: #72ae37;
$secondarycolor3-700: #69a230;
$secondarycolor3-800: #5c9326;
$secondarycolor3-900: #4a870e;

// Secondary color 4
$secondarycolor4-50: #e0f4f7;
$secondarycolor4-100: #cae6ea;
$secondarycolor4-200: #b7dce1;
$secondarycolor4-300: #a0d1d7;
$secondarycolor4-400: #85c1c9;
$secondarycolor4-500: #6eb3bc;
$secondarycolor4-600: #59a3ad;
$secondarycolor4-700: #4f959e;
$secondarycolor4-800: #437f87;
$secondarycolor4-900: #33686f;

// Accessibility
$placeholder-text-color: #75767B;
$dropdown-text-color: $primarycolor2-700;
$triggers-dropdown-color: $primarycolor2-700;
$search-result-text-color: $primarycolor2-700;
$refine-result-text-color: $primarycolor2-700;
$bookshelf-card-text-color: $primarycolor2-700;
$global-header-links-color: $primarycolor3-900;
$privacy-policy-links-color: $primarycolor3-900;
$icon-color: $primarycolor2-700;
$interactive-triggers-dropdown-color: $primarycolor2-50;
$interactive-btn-color1: #EFF6F6;
$interactive-btn-color2: #FFFFFF;
$interactive-bg-color: #F1F3F4;

// Inspire redesign colors
$flint: #425b76;
$pool: #7fd1de;
$pool-25: rgba(127, 209, 222, 0.25);
$ocean: #0096ad;
$light-blue: #b2d7ed;
$pacificBlue: #2da2b4;
$wave: #00778A;
$lagoon: #2b83ab;
$coal: #595959;
$indigo: #28799f;
$dodgerBlue: #1f8bff;
$orchid: #bd518b;
$anchor: #454f5b;
$storm: #33475b;
$darkCerulean: #004d74;
$intenseBlue: #024376;
$oxfordBlue: #002a3d;
$snow: #ffffff;
$pearl: #f6f8f9;
$dust: #f1f4f6;
$solitude: #eaf0f6;
$fog: #eaedef;
$pumice: #dee1e4;
$overcast: #ced2d6;
$tinsel: #babec3;
$earl-grey: #a7abb1;
$gravel: #92979e;
$lightSlateGrey: #8d939a;
$slateGrey: #838991;
$slate: #666b74;
$graphite: #535862;
$wrought-iron: #494e59;
$wrought-iron-3: rgba(73, 78, 89, 0.3);
$wrought-iron-55: rgba(73, 78, 89, 0.55);
$charcoal: #3e434e;
$ink: #2d333e;
$nightfall: #141619;
$cardinal: #c21414;
$tiaMaria: #c22e0e;
$sunflower: #ecb708;
$mango-tango: #D48100;
$golden-brown: #A66805;
$foliage: #0c7e11;
$persimmon: #c0462b;
$juice: #e45d3f;
$date: #893623;
$amaranth: #dc3545;
$genoa-green: #367A7B;

$green200: #094832;
$green400: #11865D;
$green500: #15A572;
$green800: #84D0B5;
$green1100: #F3FBF8;

$ins-blue-text: #516f90;
$ins-disabled-background: $solitude;
$ins-disabled-frame: #cbd6e2;
$ins-skip-link: #574751;

// Dreamten figma colors
$gray100: #252525;
$gray200: #393939;
$gray300: #5A5A5A;
$gray400: #727272;
$gray500: #9c9c9c;
$gray800: #DBDBDB;
$gray900: #e8e8e8;
$gray1000: #F2F2F2;
$gray1100: #F8F8F8;

$red200: #832000;
$red500: #E32E07;
$red600: #FF3D13;
$red800: #FF9E89;
$red1100: #fff6f5;

$yellow500: #EF8500;
$yellow800: #FFCD89;
$yellow1000: #FFF9EE;

$blue400: #035BC5;
$blue500: #0075E3;
