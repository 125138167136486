@import "./variables";
@import "./colors";

$spacer: 16px;

@function map-spacers($max-value) {
  $modifier: 0.25;
  $map: ();
  @for $i from 1 to ($max-value + 1) {
    $value: $i * $modifier * $spacer;
    $map: map-merge($map, ($i: $value));
  }
  @return $map;
}

$spacers: map-spacers(24);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$grid-gutter-width: 30px;

$modal-sm: 100%;
$modal-md: 600px;
$modal-lg: 700px;

$modal-header-border-width: 0;
$modal-footer-border-width: 0;

$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;

$theme-colors: (
  "flint": $flint,
);
