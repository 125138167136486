@import "./variables";
$icomoon-font-path: "./fonts";
@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?mhxglp');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?mhxglp#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?mhxglp') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?mhxglp') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?mhxglp##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-lists {
  &:before {
    content: $icon-lists;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-profile {
  &:before {
    content: $icon-profile;
  }
}
.icon-library {
  &:before {
    content: $icon-library;
  }
}
.icon-chat {
  &:before {
    content: $icon-chat;
  }
}
.icon-cite {
  &:before {
    content: $icon-cite;
  }
}
.icon-pin {
  &:before {
    content: $icon-pin;
  }
}
.icon-more {
  &:before {
    content: $icon-more;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-back {
  &:before {
    content: $icon-back;
  }
}
.icon-unpin {
  &:before {
    content: $icon-unpin;
  }
}
.icon-help {
  &:before {
    content: $icon-help;
  }
}
.icon-concepts {
  &:before {
    content: $icon-concepts;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-wheel {
  &:before {
    content: $icon-wheel;
  }
}
.icon-add {
  &:before {
    content: $icon-add;
  }
}
.icon-add-search-query {
  &:before {
    content: $icon-add-search-query;
  }
}
.icon-order {
  &:before {
    content: $icon-order;
  }
}
.icon-angle-down-solid {
  &:before {
    content: $icon-angle-down-solid;
  }
}
.icon-angle-up-solid {
  &:before {
    content: $icon-angle-up-solid;
  }
}
.icon-angle-down-thin {
  &:before {
    content: $icon-angle-down-thin;
  }
}
.icon-angle-up-thin {
  &:before {
    content: $icon-angle-down-thin;
  }
  transform: rotate(180deg);
}
.icon-angle-right-thin {
  &:before {
    content: $icon-angle-down-thin;
  }
  transform: rotate(-90deg);
}
.icon-angle-left-thin {
  &:before {
    content: $icon-angle-down-thin;
  }
  transform: rotate(90deg);
}
.icon-move {
  &:before {
    content: $icon-move;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-exclude {
  &:before {
    content: $icon-exclude;
  }
}
.icon-lock-open {
  &:before {
    content: $icon-lock-open;
  }
}
.icon-error {
  &:before {
    content: $icon-error;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-rotate {
  &:before {
    content: $icon-rotate;
  }
}
.icon-overflow-toggle {
  &:before {
    content: $icon-overflow-toggle;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-related-resources {
  &:before {
    content: $icon-related-resources;
  }
}
.icon-contributors {
  &:before {
    content: $icon-contributors;
  }
}
.icon-topics {
  &:before {
    content: $icon-topics;
  }
}
.icon-failure .path1 {
  &:before {
    content: $icon-failure-path1;
  }
}
.icon-failure .path2 {
  &:before {
    content: $icon-failure-path2;
    margin-left: -1em;
  }
}
.icon-ins-pin {
  &:before {
    content: $icon-ins-pin;
  }
}
.icon-success .path1 {
  &:before {
    content: $icon-success-path1;
  }
}
.icon-success .path2 {
  &:before {
    content: $icon-success-path2;
    margin-left: -1em;
  }
}
.icon-ins-wheel {
  &:before {
    content: $icon-ins-wheel;
  }
}
.icon-overflow {
  &:before {
    content: $icon-overflow;
  }
}
.icon-ins-unpin {
  &:before {
    content: $icon-ins-unpin;
  }
}
.icon-ins-search {
  &:before {
    content: $icon-ins-search;
  }
}
.icon-ins-user {
  &:before {
    content: $icon-ins-user;
  }
}
.icon-ins-info {
  &:before {
    content: $icon-ins-info;
  }
}
