.ins-buttons.button-secondary.flint.button-secondary--inverse-on-hover {
  &:hover, &.hover, &:focus, &.focused {
    box-shadow: none;
    background: $flint;
    color: $white;
  }

  &:focus, &.focused {
    border-width: 1px;
  }

  &:active, &.pressed {
    box-shadow: none;
    border-width: 1px;
  }
}
