/* Hide scrollbar for Chrome, Safari and Opera */
.ps::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ps {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
}

/* Override container style to fix touch action on some devices */
perfect-scrollbar .ps {
  overflow: auto !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  overscroll-behavior: contain !important;
}
