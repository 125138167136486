@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.mat-tooltip.tooltip {
  margin-top: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
  padding: 16px;
  font-family: 'Roboto', sans-serif;
  @include font-mixin(14px, normal, 1.43, $primarycolor2-50);
  background-color: $primarycolor2-800;
	width: 200px;

  &:after {
    position: absolute;
    content: " ";
    top: -8px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid $primarycolor2-800;
  }
}
